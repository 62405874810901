*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
}

.ui.secondary.menu .active.item:hover {
  background: rgba(0, 0, 0, 0.4);
}
.ui.secondary.menu .active.item {
  background: rgba(0, 0, 0, 0.4);
}
